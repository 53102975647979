import config from "../config";

export default {
    config: config,
};

const base = config.base_folder;

export const vendormenuItems = [
    {
        id: 1,
        label: "Dashboard",
        icon: "bx-home-circle",
        link: base + "vendor/dashboard",
    },
    {
        id: 2,
        label: "Orders",
        icon: "bx bx-receipt",
        link: base + "vendor/orders/list",
        badge: {
            variant: "danger",
            text: "order",
        },
    },
    {
        id: 15,
        label: "Subscriptions",
        icon: "bx bx-receipt",
        link: base + "vendor/subscription/list",
    },

    {
        id: 3,
        label: "Catalog",
        icon: "bx bx-book",
        link: base + "vendor/vendors/catalog",
    },
    {
        id: 4,
        label: "Operation Time",
        icon: "bx bx-time",
        link: base + "vendor/operation-time",
    },
    {
        id: 5,
        label: "Category",
        icon: "bx bx-dish",
        link: base + "vendor/vendor-category",
    },
    {
        id: 6,
        label: "Menu time-slot",
        icon: "bx bx-time",
        link: base + "vendor/selling-timeslot",
    },
    {
        id: 7,
        label: "Promocode",
        icon: "bx bx-purchase-tag-alt",
        link: base + "vendor/coupon/list",
    },
    {
        id: 8,
        label: "Discount",
        icon: "bx-purchase-tag",
        link: base + "vendor/vendor-discount",
    },
    // {
    //     id: 9,
    //     label: "My Profile",
    //     icon: "bx bx-user",
    //     link: base+"vendor/vendor-profile",
    // },
    {
        id: 10,
        label: "Finance",
        icon: "bx bx-file", //bx-rupee
        link: base + "vendor/finance",
    },
    {
        id: 11,
        label: "Reports",
        icon: "bx bx-notepad",
        link: base + "vendor/reports",
    },
    {
        id: 12,
        label: "Reviews",
        icon: "bx bx-star",
        link: base + "vendor/vendor-rating",
    },
    {
        id: 13,
        label: "Tag",
        icon: "bx bx-food-tag",
        link: base + "vendor/vendor-tag",
    },
    // {
    //     id: 14,
    //     label: "Pos",
    //     icon: "bx-home-circle",
    //     link: base+'vendor/pos'
    // }
];
