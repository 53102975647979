var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SiteLogo'),_vm._v(" "),_c('div',{attrs:{"id":"sidebar-menu"}},[_c('ul',{staticClass:"metismenu list-unstyled",attrs:{"id":"side-menu"}},[_vm._l((_vm.menuItems),function(item){return [(item.isTitle)?_c('li',{key:item.id,staticClass:"menu-title",attrs:{"id":'side-' + item.id}},[_vm._v("\n          "+_vm._s(item.label == "Vendor" ? item.label : _vm.getTranslate(item.label))+"\n        ")]):_vm._e(),_vm._v(" "),(!item.isTitle && !item.isLayout)?_c('li',{key:item.id,attrs:{"id":'side-' + item.id}},[(_vm.hasItems(item))?_c('a',{staticClass:"is-parent",class:{
              'has-arrow': !!!(item.badge && _vm.getBadge(item.badge.text) !== ''),
              'has-dropdown': item.badge,
            },attrs:{"href":"javascript:void(0);"}},[(item.icon)?_c('i',{class:`bx ${item.icon}`}):_vm._e(),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.getTranslate(item.label)))]),_vm._v(" "),(item.badge && _vm.getBadge(item.badge.text) !== '')?_c('span',{class:`badge badge-pill badge-${item.badge.variant} float-right`},[_vm._v(_vm._s(_vm.getBadge(item.badge.text)))]):_vm._e()]):_vm._e(),_vm._v(" "),(!_vm.hasItems(item))?_c('router-link',{staticClass:"side-nav-link-ref flex-grow-1 d-flex align-items-center gap-3",attrs:{"to":item.link}},[(item.icon)?_c('i',{class:`bx ${item.icon}`}):_vm._e(),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.getTranslate(item.label)))]),_vm._v(" "),(item.badge && _vm.getBadge(item.badge.text) !== '')?_c('span',{class:`badge badge-pill badge-${item.badge.variant} float-right`},[_vm._v(_vm._s(_vm.getBadge(item.badge.text)))]):_vm._e(),_vm._v(" "),(
                item.badge &&
                item.label !== '' &&
                item.label == 'Chat' &&
                _vm.$auth.unreadMessages.length > 0
              )?_c('span',{class:`badge badge-pill badge-${item.badge.variant} ml-auto`},[_c('span',{staticClass:"chat-span"},[_vm._v("\n                "+_vm._s(_vm.$auth.unreadMessages.filter((urm) => urm.type == "customer").length +
                  _vm.$auth.unreadMessages.filter((urm) => urm.type == "vendor").length +
                  _vm.$auth.unreadMessages.filter((urm) => urm.type == "driver").length))])]):_vm._e(),_vm._v(" "),(
                item.badge &&
                item.label !== '' &&
                item.label == 'Orders' &&
                _vm.$auth.orders.length > 0
              )?_c('span',{class:`badge badge-pill badge-${item.badge.variant} ml-auto`},[_c('span',{staticClass:"chat-span"},[_vm._v(" "+_vm._s(_vm.$auth.orders.length))])]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.hasItems(item))?_c('ul',{staticClass:"sub-menu",attrs:{"aria-expanded":"false"}},_vm._l((item.subItems),function(subitem,index){return _c('li',{key:index},[(!_vm.hasItems(subitem))?_c('router-link',{staticClass:"side-nav-link-ref",attrs:{"to":subitem.link}},[_c('span',[_vm._v(_vm._s(_vm.getTranslate(subitem.label)))]),_vm._v(" "),(subitem.badge && _vm.getBadge(subitem.badge.text) !== '')?_c('span',{class:`badge badge-pill badge-${subitem.badge.variant} float-right`},[_vm._v(_vm._s(_vm.getBadge(subitem.badge.text)))]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.hasItems(subitem))?_c('a',{staticClass:"side-nav-link-a-ref has-arrow",attrs:{"href":"javascript:void(0);"}},[_vm._v(_vm._s(_vm.getTranslate(subitem.label)))]):_vm._e(),_vm._v(" "),(_vm.hasItems(subitem))?_c('ul',{staticClass:"sub-menu mm-collapse",attrs:{"aria-expanded":"false"}},_vm._l((subitem.subItems),function(subSubitem,i){return _c('li',{key:100 + i},[_c('router-link',{staticClass:"side-nav-link-ref",attrs:{"to":subSubitem.link}},[_c('span',[_vm._v(_vm._s(_vm.getTranslate(subSubitem.label)))])])],1)}),0):_vm._e()],1)}),0):_vm._e()],1):_vm._e()]}),_vm._v(" "),(this.$auth.user.platform == 2)?_c('li',[_c('router-link',{attrs:{"to":{ name: 'getting-started' }}},[_c('i',{staticClass:"bx bx-help-circle d-none d-xl-inline-block"}),_vm._v("\n          Getting Started\n        ")])],1):_vm._e(),_vm._v(" "),(
          this.$auth.user &&
          this.$auth.user.parent_vendor_id == null &&
          this.$auth.user.parent_vendor_id == '' &&
          (this.$auth.user.status == 7 || this.$auth.user.status == '7')
        )?_c('li',[_c('router-link',{attrs:{"to":{ name: 'pricing-plans' }}},[_c('i',{staticClass:"bx bx-notepad d-none d-xl-inline-block"}),_vm._v("\n          Pricing\n        ")])],1):_vm._e()],2),_vm._v(" "),(!_vm.$auth.partner_login && this.$auth.user.vendor_role != '1')?_c('div',{staticClass:"btn-side-feedback"}):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }